import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import CommercialPropertiesAdd from "@components/commercial-properties/Add"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "commercial-properties.create"
}

const CommercialPropertiesAddPage = (props) => {
  return (
  <>
    <Seo title="Add Commercial Property" />
      <div className="main-wrapper">
        <Sidebar menuActive="commercial-properties" 
          action="Add"/>
        <CommercialPropertiesAdd 
          menu="Add Commercial Properties" 
          parentPath="commercial-properties" 
          pageType="Add"
          additionalParams={AdditionalParams}/>
      </div>
    </>
  )
}

export default CommercialPropertiesAddPage;
